<template>
  <div class="hello">
    <!-- <h1> QQ:{{qq}} 获取Vue源码 </h1> -->
    <!-- <input type="button" value="打印" @click="printGraph()"> -->
    <el-button type="primary" plain @click="getPdf('#DomPdf')">下载</el-button>
    <div id="DomPdf" style="width:100%;height:auto;">
      <Graphics v-if="get_data" :value="value"></Graphics>

    </div>
  </div>
</template>

<script>
import Graphics from './temperature/graphics'
// import dataModel from './data'
let PRINT_ID = 0
export default {
  name: 'HelloWorld',
  props: {
    userId: String,  // 【其他平台】读取的属性
  },
  components: {
    Graphics
  },
  data() {
    return {
      qq: process.env.VUE_APP_QQ,
      get_data: false,
      uid: this.userId,
      htmlTitle: '体温单',
      value: {
        grParamBOS: [
          {
            age: 66,
            ageunit: '岁',
            beginDate: '2024-06-06',
            cwh: '0',
            hospCode: '0000000006',
            originName: '测试医院名称',
            hospDate: 1608889359000,
            hospDays: 0,
            inDate: 1611666680000,
            inDiagName: '诊断内容',
            name: '测试姓名',
            officeName: 'XX科室',
            operaDays: null,
            sex: '',
            weekNo: '0'
          }
        ],
        rows: [],
        types: [],
      },
    }
  },
  created() {
    this.getToday()
    this.getData()
  },
  methods: {
    TimeProcess(time) {
      let y = time.getFullYear();
      let MM = time.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = time.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + MM + '-' + d
    },
    getToday() {
      var today = new Date();
      var formerday = new Date(today);
      formerday.setDate(today.getDate() - 7);
      this.year_today = today.getFullYear()
      this.year_formerday = formerday.getFullYear()
      this.endTime = this.TimeProcess(today)
      this.startTime = this.TimeProcess(formerday)

    },
    getData() {
      console.log('看看uid', this.uid)
      // this.value = dataModel
      // console.log('看看数据', this.value.rows)

      // setTimeout(() => {
      //   // console.log('看看数据', this.value)
      //   // console.log('看看数据', this.value.rows)
      // }, 1000)
      // this.uid = 2
      // this.startTime = '2024-06-06'
      // this.endTime = '2024-06-12'
      this.$http({
        url: this.$http.adornUrl('/personal/info/app/getPersonalInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$set(this.value.grParamBOS[0], 'name', data.info.realName);
          this.$set(this.value.grParamBOS[0], 'age', data.info.age);
          var genderMap = { 0: "女", 1: "男" }
          this.$set(this.value.grParamBOS[0], 'sex',  genderMap[data.info.gender]);
          // this.info[0]["value"] = data.realName
          // this.info[2]["value"] = data.age

          // var genderMap = { 0: "女", 1: "男" }
          // this.info[1]["value"] = genderMap[data.gender]
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      }),
      this.$http({
        url: this.$http.adornUrl("/personal/personaltemperature/app/queryThreeItems"),
        method: "post",
        data: this.$http.adornData({ uid: this.uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看查询数据");
          console.log(data);
          this.$set(this.value, 'rows', data.data);
          // console.log(this.value.rows)
          this.$set(this.value.grParamBOS[0], 'beginDate', this.startTime);
          this.$set(this.value,'types', data.types);
          console.log('看看时间', this.startTime, this.endTime)
          this.get_data = true
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });

    },

  }
}
</script>
